<template>
  <Wrapper>
    <div class="desc">
      1925安心專線GO GO GO，對照位置點擊可愛的藍寶和金妮，連續正確點擊愈高分唷！
    </div>
    <div class="button" @click="$emit('ready')">Ready</div>
  </Wrapper>
</template>

<script>
import Wrapper from '@/components/Game/Wrappers/Popup.vue';

export default {
  components: { Wrapper },
};
</script>

<style lang="scss" scoped>
.open-music-pop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  .desc {
    margin-bottom: 20px;
    width: 80%;
  }
  .button {
    cursor: pointer;
    background-color: #fee4a4;
    color: #4c2a12;
    font-size: 20px;
    text-align: center;
    width: 100px;
    height: 40px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
