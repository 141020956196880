<template>
    <div class="stage2">
        <StageWrapper
            ref="stage"
            :stage-config="stageConfig"
            :score="score"
            @due="$emit('end', score)"
        >
            <div class="question">{{ questionConfig.question }}</div>
            <div class="selections">
                <div
                    v-for="(selection, index) in questionConfig.selections"
                    :key="index"
                    :class="{
                        correct: judgeCorrect(
                            selected,
                            selection,
                            questionConfig.correctAnswer
                        ),
                        incorrect: judgeIncorrect(
                            selected,
                            selection,
                            questionConfig.correctAnswer
                        ),
                    }"
                    @click="onClick(selection)"
                >
                    {{ selection.label }}
                </div>
            </div>
        </StageWrapper>
        <transition name="fade" mode="out-in">
            <Popup v-if="popupState" @ready="onReady" />
        </transition>
    </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import StageWrapper from "@/components/Game/Wrappers/StageWrapper.vue";
import Popup from "@/components/Game/Popups/Stage2.vue";
import STAGE_CONFIG from "@/config/stageConfig";
import QUESTION_CONFIG from "@/config/questionConfig";
import questionSelectStage from "@/mixins/questionSelectStage";

export default {
    mixins: [questionSelectStage],
    components: { StageWrapper, Popup },
    data() {
        return {
            popupState: true,
            answers: {},

            cerrentQuestion: 1,
            selected: undefined,
            questionPool: QUESTION_CONFIG.stage2,
            questionOrder: [],
            questionConfig: {},
            stageConfig: STAGE_CONFIG.stage2,
            score: 0,
        };
    },
    computed: {
        ...mapState({
            user: (stage) => stage.user,
        }),
    },
    created() {
        this.setQuestionOrder();
        this.questionConfig = this.questionPool[this.questionOrder.pop()];
    },
    methods: {
        setQuestionOrder() {
            const ORDER = [];
            this.questionPool.forEach((item, index) => {
                ORDER.push(index);
            });
            this.questionOrder = _.shuffle(ORDER);
        },
        onReady() {
            this.popupState = false;
            this.$refs.stage.$refs.countdown.onStart();
        },
        onClick(selection) {
            if (this.selected) return;
            this.selected = selection.value;
            if (
                this.judgeCorrect(
                    this.selected,
                    selection,
                    this.questionConfig.correctAnswer
                )
            )
                this.score += 1;
            this.onChangeQuestion(1000);
        },
        onChangeQuestion(delay) {
            setTimeout(() => {
                this.selected = undefined;
                this.cerrentQuestion += 1;
                this.questionConfig =
                    this.questionPool[this.questionOrder.pop()];
                if (this.questionOrder.length === 0) this.setQuestionOrder();
            }, delay || 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/game/QuestionSelectStage.scss";
</style>
