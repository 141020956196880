<template>
    <div class="stage5">
        <StageWrapper
            ref="stage"
            :stage-config="stageConfig"
            :score="score"
            @due="$emit('end', score)"
            @tick="onTick"
        >
            <div class="main">
                <div class="card-board">
                    <div
                        v-for="(row, i) in cardMap"
                        :key="`row${i}`"
                        class="row"
                    >
                        <div
                            v-for="(card, j) in row"
                            :key="`card${j}`"
                            class="card"
                            :class="{ flipped: flippedMap[i][j] }"
                            @click="onClick(i, j)"
                        >
                            <div class="front-layout">
                                <img :src="card" alt="" height="90%">
                            </div>
                            <div class="back-layout"></div>
                        </div>
                    </div>
                </div>
            </div>
        </StageWrapper>
        <transition name="fade" mode="out-in">
            <Popup v-if="popupState" @ready="onReady" />
        </transition>
    </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import StageWrapper from "@/components/Game/Wrappers/StageWrapper.vue";
import Popup from "@/components/Game/Popups/Stage5.vue";
import STAGE_CONFIG from "@/config/stageConfig";
import QUESTION_CONFIG from "@/config/questionConfig";
import questionSelectStage from "@/mixins/questionSelectStage";

export default {
    mixins: [questionSelectStage],
    components: { StageWrapper, Popup },
    data() {
        return {
            timeLast: 0,
            popupState: true,
            answers: {},

            cerrentQuestion: 1,
            selected: undefined,
            cardItems: QUESTION_CONFIG.stage5,
            cardMap: [],
            flippedMap: [
                [false, false, false],
                [false, false, false],
                [false, false, false],
                [false, false, false],
            ],
            lockFlip: false,
            stageConfig: STAGE_CONFIG.stage5,
            score: 0,
        };
    },
    computed: {
        ...mapState({
            user: (stage) => stage.user,
        }),
    },
    created() {
        this.shuffleQuestion();
    },
    methods: {
        shuffleQuestion() {
            const ALL = _.shuffle([...this.cardItems, ...this.cardItems]);
            const COL = 3;
            const ROW = 4;
            for (let i = 0; i < ROW; i += 1) {
                const TEMP = [];
                for (let j = 0; j < COL; j += 1) {
                    TEMP.push(ALL.pop());
                }
                this.cardMap.push(TEMP);
            }
        },
        startFlip(duration) {
            this.lockFlip = true;
            this.cardMap.forEach((item, index) => {
                this.flippedMap.splice(index, 1, [true, true, true]);
            });
            setTimeout(() => {
                this.cardMap.forEach((item, index) => {
                    this.flippedMap.splice(index, 1, [false, false, false]);
                });
                this.lockFlip = false;
                this.$refs.stage.$refs.countdown.onStart();
            }, duration * 1000);
        },
        onTick(time) {
            this.timeLast = time;
        },
        onReady() {
            this.popupState = false;
            this.startFlip(3);
        },
        onClick(x, y) {
            if (this.lockFlip) return;
            if (this.flippedMap[x][y] === "COMPLETE") return;

            this.flippedMap[x].splice(y, 1, true);
            this.onCheckActionEnd();
        },
        onCheckActionEnd() {
            let flippedAmount = 0;
            const target = [];
            this.flippedMap.forEach((row, x) => {
                row.forEach((state, y) => {
                    if (state && state !== "COMPLETE") {
                        flippedAmount += 1;
                        target.push(`${x}-${y}`);
                    }
                });
            });
            if (flippedAmount === 2) this.judge(target);
        },
        judge(target) {
            this.lockFlip = true;
            const ST = target[0].split("-");
            const ST_X = ST[0];
            const ST_Y = ST[1];
            const ED = target[1].split("-");
            const ED_X = ED[0];
            const ED_Y = ED[1];

            const ST_INNER = this.cardMap[ST_X][ST_Y];
            const ED_INNER = this.cardMap[ED_X][ED_Y];

            if (ST_INNER === ED_INNER) {
                this.score += 1;
                this.flippedMap[ST_X].splice(ST_Y, 1, "COMPLETE");
                this.flippedMap[ED_X].splice(ED_Y, 1, "COMPLETE");
                this.lockFlip = false;
                this.judgeOver();
            } else {
                setTimeout(() => {
                    this.flippedMap[ST_X].splice(ST_Y, 1, false);
                    this.flippedMap[ED_X].splice(ED_Y, 1, false);
                    this.lockFlip = false;
                }, 800);
            }
        },
        judgeOver() {
            let completedAmount = 0;
            this.flippedMap.forEach((row, x) => {
                row.forEach((state, y) => {
                    if (state === "COMPLETE") completedAmount += 1;
                });
            });
            if (
                completedAmount ===
                this.cardMap.length * this.cardMap[0].length
            )
                setTimeout(() => {
                    this.$emit("end", this.score + this.timeLast);
                }, 300);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/game/QuestionSelectStage.scss";
.main {
    flex: 1;
    display: flex;
    .card-board {
        width: 95%;
        height: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .row {
            display: flex;
            height: 24%;
            justify-content: space-between;
        }
        .card {
            transition: transform 0.3s;
            position: relative;
            cursor: pointer;
            // border: 1px solid;
            background-color: #fff;
            width: 32%;
            transform-style: preserve-3d;
            transform: rotateY(180deg);
            > * {
                position: absolute;
                border: solid 1px #517fc2;
                border-radius: 6.7px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .back-layout {
                background-color: #be9adb;
                transform: rotateY(-180deg);
            }
            &.flipped {
                transform: unset;
            }
        }
    }
}
</style>
