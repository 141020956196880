export default [
  // #1
  {
    question: "請將下列類型的憂鬱症症狀，正確配對",
    subQuestion: [
      {
        label: "妨礙每日正常運作",
      },
      {
        label: "無法順暢發揮日常功能",
      },
      {
        label: "生產後，感到強烈且持續已久的憂鬱",
      },
      {
        label: "常在秋季或冬季發作或高緯度的居民時常發生",
      },
    ],
    options: [
      {
        label: "輕鬱症",
        value: 1,
      },
      {
        label: "重鬱症",
        value: 0,
      },
      {
        label: "產後憂鬱症",
        value: 2,
      },
      {
        label: "冬季憂鬱症",
        value: 3,
      },
    ]
  },
  // #2
  {
    question: "請將下列思覺失調症的相關症狀進行配對",
    subQuestion: [
      {
        label: "妄想、幻覺、幻聽",
      },
      {
        label: "聯想障礙、情感矛盾",
      },
      {
        label: "難以專注、學習障礙",
      },
    ],
    options: [
      {
        label: "負性症狀",
        value: 1,
      },
      {
        label: "認知障礙",
        value: 2,
      },
      {
        label: "正性症狀",
        value: 0,
      },
    ]
  },
  // #3
  {
    question: "配對與「SMILE口訣」相對應的項目",
    subQuestion: [
      {
        label: "S",
      },
      {
        label: "Ｍ",
      },
      {
        label: "I",
      },
      {
        label: "L",
      },
      {
        label: "E",
      },
    ],
    options: [
      {
        label: "睡得好",
        value: 0,
      },
      {
        label: "抬槓、聊天",
        value: 2,
      },
      {
        label: "吃得健康",
        value: 4,
      },
      {
        label: "微笑",
        value: 3,
      },
      {
        label: "活動",
        value: 1,
      },
    ]
  },
  // #4
  {
    question: "請將下列情緒困擾、心理壓力諮詢之專線號碼，正確配對：",
    subQuestion: [
      {
        label: "生命線",
      },
      {
        label: "安心專線",
      },
      {
        label: "張老師",
      },
      {
        label: "孕產婦關懷專線",
      },
      {
        label: "男性關懷專線",
      },
    ],
    options: [
      {
        label: "1980",
        value: 2,
      },
      {
        label: "1925",
        value: 1,
      },
      {
        label: "0800-013-999",
        value: 4,
      },
      {
        label: "0800-870-870",
        value: 3,
      },
      {
        label: "1995",
        value: 0,
      },
    ]
  },
  // #5
  {
    question: "網路成癮者通常會出現的核心症狀與負面影響有哪些特性：",
    subQuestion: [
      {
        label: "不能上網時出現了身心症狀，如:煩躁不安、焦慮、悲傷…等。",
      },
      {
        label: "上網的慾望越來越不能滿足，所需上網時間越來越長",
      },
      {
        label: "不能克制上網的衝動",
      },
    ],
    options: [
      {
        label: "強迫性",
        value: 2,
      },
      {
        label: "耐受性",
        value: 1,
      },
      {
        label: "戒斷性",
        value: 0,
      },
    ]
  },
  // #6
  {
    question: "請將自殺守門人3步驟敘述，正確配對",
    subQuestion: [
      {
        label: "主動關懷與積極傾聽",
      },
      {
        label: "適當回應與支持陪伴",
      },
      {
        label: "資源轉介與持續關懷",
      },
    ],
    options: [
      {
        label: "1問",
        value: 0,
      },
      {
        label: "2應",
        value: 1,
      },
      {
        label: "3轉介",
        value: 2,
      },
    ]
  },
]