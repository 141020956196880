export default {
  methods: {
    judgeCorrect(selected, selection, correctAnswer) {
      return (
        (selected === selection.value && !correctAnswer) ||
        (selected && selection.value === correctAnswer)
      );
    },
    judgeIncorrect(selected, selection, correctAnswer) {
      return (
        correctAnswer &&
        selected === selection.value &&
        selection.value !== correctAnswer
      );
    },
  }
}
