<template>
    <div class="score">
        <div class="inner">
            <div class="icon">
                <img
                    src="@/assets/game/stages/components/score.png"
                    alt=""
                    width="100%"
                />
            </div>
            <div class="text">
                <div class="label">Score</div>
                <div class="time-last">{{ score }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        score: Number,
    },
};
</script>

<style lang="scss" scoped>
.score {
    width: 100%;
    margin: auto;
    padding: 2px 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: solid 2px #ffcfcf;
    border-right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    .inner {
        width: 80%;
        margin: auto;
        display: flex;
        align-items: center;
    }
    .icon {
        margin-right: 10px;
        width: 30px;
    }
    .text {
        color: #ce4a4a;
        font-weight: bold;
        .label {
            font-size: 12px;
        }
        .time-last {
            font-size: 20px;
        }
    }
}
</style>
