var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stage0"},[_c('StageWrapper',{attrs:{"stage-config":_vm.stageConfig,"score":_vm.score},on:{"due":function($event){return _vm.$emit('end', _vm.score)}}},[_c('div',{staticClass:"question",domProps:{"innerHTML":_vm._s(_vm.questionConfig.question)}}),_c('div',{staticClass:"selections"},_vm._l((_vm.questionConfig.selections),function(selection,index){return _c('div',{key:index,class:{
                    correct: _vm.judgeCorrect(
                        _vm.selected,
                        selection,
                        _vm.questionConfig.correctAnswer
                    ),
                    incorrect: _vm.judgeIncorrect(
                        _vm.selected,
                        selection,
                        _vm.questionConfig.correctAnswer
                    ),
                },on:{"click":function($event){return _vm.onClick(selection)}}},[_vm._v(" "+_vm._s(selection.label)+" ")])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }