export default {
  exampleConfig: {
    stage: 0, // stage index
    duration: 300, // second of countdown plugin
    plugins: {
      score: true, // show score plugin or not
    },
  },
  stage0: {
    stage: 0,
  },
  stage1: {
    stage: 1,
    duration: 30,
    plugins: {
      score: true,
    },
  },
  stage2: {
    stage: 2,
    duration: 30,
    plugins: {
      score: true,
    },
  },
  stage3: {
    stage: 3,
    duration: 30,
    plugins: {
      score: true,
    },
  },
  stage4: {
    stage: 4,
    duration: 30,
    plugins: {
      score: true,
    },
  },
  stage5: {
    stage: 5,
    duration: 30,
    plugins: {
      score: true,
    },
  },
};
