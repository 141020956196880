<template>
    <div
        v-if="innerWidth && innerHeight"
        :style="{ width: `${innerWidth}px`, height: `${innerHeight}px` }"
        class="page-wrapper"
    >
        <slot />
    </div>
</template>

<script>
export default {
    data() {
        return {
            innerWidth: undefined,
            innerHeight: undefined,
        };
    },
    mounted() {
        this.init();
        window.addEventListener("resize", () => {
            setTimeout(this.init, 300);
        });
    },
    methods: {
        init() {
            const RATE = 750 / 1358;
            const isHeightEnough =
                window.innerHeight >= window.innerWidth / RATE;

            const isDesktopScreen = window.innerWidth > 414;
            if (isDesktopScreen) {
                const isDesktopScreenHeightEnough =
                    window.innerHeight >= 414 / RATE;
                if (isDesktopScreenHeightEnough) {
                    this.innerWidth = 414;
                    this.innerHeight = 414 / RATE;
                    return;
                }
                this.innerWidth = window.innerHeight * RATE;
                this.innerHeight = window.innerHeight - this.$root.navbarHeight;
                return;
            }
            this.innerWidth = window.innerWidth;
            if (isHeightEnough) this.innerHeight = window.innerWidth / RATE - this.$root.navbarHeight;
            else this.innerHeight = window.innerHeight - this.$root.navbarHeight;
        },
    },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
    position: relative;
    margin: auto;
    overflow: hidden;
}
</style>
