import stage0 from './stage0';
import stage1 from './stage1';
import stage2 from './stage2';
import stage4 from './stage4';
import stage5 from './stage5';

export default {
  stage0,
  stage1,
  stage2,
  stage4,
  stage5,
}
