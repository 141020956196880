import CARD1 from '@/assets/game/stages/stage5/1.png';
import CARD2 from '@/assets/game/stages/stage5/2.png';
import CARD3 from '@/assets/game/stages/stage5/3.png';
import CARD4 from '@/assets/game/stages/stage5/4.png';
import CARD5 from '@/assets/game/stages/stage5/5.png';
import CARD6 from '@/assets/game/stages/stage5/6.png';

export default [
  CARD1,
  CARD2,
  CARD3,
  CARD4,
  CARD5,
  CARD6,
]
