export default [
  {
    openDate: "20210901",
  },
  {
    openDate: "20210901",
  },
  {
    openDate: "20211006",
  },
  {
    openDate: "20211015",
  },
  {
    openDate: "20211022",
  },
  {
    openDate: "20211022",
  },
]