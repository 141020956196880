export default [
  // #1
  {
    question: "產後主要因＿＿發生變化,約有五到八成的媽媽可能有產後情緒低落情形",
    selections: [
      {
        label: "賀爾蒙",
        value: "A",
      },
      {
        label: "呼吸功能",
        value: "B",
      },
      {
        label: "肝功能",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #2
  {
    question: "產後持續__以上的憂鬱,如:暴躁、失眠、激動易怒、容易自責、罪惡感、無法專注照料嬰兒的相關事物極有可能是產後憂鬱。",
    selections: [
      {
        label: "1週",
        value: "A",
      },
      {
        label: "2週",
        value: "B",
      },
      {
        label: "1個月",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #3
  {
    question: "產後憂鬱症主要發生在。",
    selections: [
      {
        label: "腦部",
        value: "A",
      },
      {
        label: "心臟",
        value: "B",
      },
      {
        label: "子宮",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #4
  {
    question: "老年憂鬱症的產生可能與＿＿減少有關",
    selections: [
      {
        label: "血清素",
        value: "A",
      },
      {
        label: "膠原蛋白",
        value: "B",
      },
      {
        label: "維生素",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #5
  {
    question: "下列何者不是老年憂鬱症常見症狀",
    selections: [
      {
        label: "食慾大增",
        value: "A",
      },
      {
        label: "焦慮不安",
        value: "B",
      },
      {
        label: "呼吸急促",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #6
  {
    question: "何者為網路成癮最常見的型態",
    selections: [
      {
        label: "網路遊戲成癮",
        value: "A",
      },
      {
        label: "色情網站",
        value: "B",
      },
      {
        label: "社群網站成癮",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #7
  {
    question: "孕產婦關懷諮詢專線",
    selections: [
      {
        label: "0800-870-870",
        value: "A",
      },
      {
        label: "0800-078-078",
        value: "B",
      },
      {
        label: "0800-087-087",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #8
  {
    question: "何者不是產後憂鬱症的高風險族群",
    selections: [
      {
        label: "高血壓患者",
        value: "A",
      },
      {
        label: "憂鬱症家族史",
        value: "B",
      },
      {
        label: "婚姻關係不良",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #9
  {
    question: "產後情緒低落通常發生於",
    selections: [
      {
        label: "產後3~10天",
        value: "A",
      },
      {
        label: "產後10~15天",
        value: "B",
      },
      {
        label: "產後15~20天",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #10
  {
    question: "長照專線電話號碼為？",
    selections: [
      {
        label: "1966",
        value: "A",
      },
      {
        label: "1988",
        value: "B",
      },
      {
        label: "1977",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #11
  {
    question: "老朋友專線號碼為？",
    selections: [
      {
        label: "0800-228585",
        value: "A",
      },
      {
        label: "0900-123123",
        value: "B",
      },
      {
        label: "0800-4565456",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #12
  {
    question: "家庭照顧者關懷專線",
    selections: [
      {
        label: "0800-507272",
        value: "A",
      },
      {
        label: "0800-505050",
        value: "B",
      },
      {
        label: "0800-772772",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #13
  {
    question: "踹貢少年專線",
    selections: [
      {
        label: "0800-001769",
        value: "A",
      },
      {
        label: "0800-100100",
        value: "B",
      },
      {
        label: "0800-769769",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #14
  {
    question: "何者不是老年人自殺的高危險因子？",
    selections: [
      {
        label: "慢性身體疾病",
        value: "A",
      },
      {
        label: "憂鬱症",
        value: "B",
      },
      {
        label: "天天運動",
        value: "C",
      },
    ],
    correctAnswer: 'C',
  },
  // #15
  {
    question: "何者是思覺失調的症狀之一",
    selections: [
      {
        label: "混亂言談",
        value: "A",
      },
      {
        label: "長痘痘",
        value: "B",
      },
      {
        label: "便秘",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #16
  {
    question: "何者是思覺失調的負性症狀之一",
    selections: [
      {
        label: "面無表情",
        value: "A",
      },
      {
        label: "幻覺",
        value: "B",
      },
      {
        label: "焦慮",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #17
  {
    question: "下列哪種校園霸凌最常見？",
    selections: [
      {
        label: "言語霸凌",
        value: "A",
      },
      {
        label: "關係霸凌",
        value: "B",
      },
      {
        label: "網路霸凌",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #18
  {
    question: "孕媽咪的神隊友要具備什麼技能?",
    selections: [
      {
        label: "分擔家務",
        value: "A",
      },
      {
        label: "多讚美、多肯定",
        value: "B",
      },
      {
        label: "處理來自長輩、親友的壓力",
        value: "C",
      },
      {
        label: "以上皆是",
        value: "D",
      },
    ],
    correctAnswer: 'D',
  },
  // #19
  {
    question: "網路成癮者通常會出現的核心症狀與負面影響包括",
    selections: [
      {
        label: "煩躁不安、焦慮、悲傷",
        value: "A",
      },
      {
        label: "所需上網時間越來越長",
        value: "B",
      },
      {
        label: "不能克制上網的衝動",
        value: "C",
      },
      {
        label: "以上皆是",
        value: "D",
      },
    ],
    correctAnswer: 'D',
  },
  // #20
  {
    question: "何者不是健康上網7撇步？",
    selections: [
      {
        label: "連續玩電動5小時",
        value: "A",
      },
      {
        label: "30分鐘休息一次",
        value: "B",
      },
      {
        label: "走出戶外玩",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #21
  {
    question: "何者不是染毒四徵兆之一？",
    selections: [
      {
        label: "行為異常",
        value: "A",
      },
      {
        label: "情緒不穩",
        value: "B",
      },
      {
        label: "穿著改變",
        value: "C",
      },
      {
        label: "情緒不穩",
        value: "D",
      },
    ],
    correctAnswer: 'C',
  },
  // #22
  {
    question: "請問戒毒成功專線號碼是？",
    selections: [
      {
        label: "0800770885",
        value: "A",
      },
      {
        label: "0800123456",
        value: "B",
      },
      {
        label: "0800654321",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #23
  {
    question: "酒癮患者無法入睡該怎麼辦",
    selections: [
      {
        label: "喝茶、可樂、酒等刺激性飲料",
        value: "A",
      },
      {
        label: "食用鹹酥雞、滷味等重口味食物",
        value: "B",
      },
      {
        label: "喝溫牛奶、練習肌肉放鬆技巧",
        value: "C",
      },
    ],
    correctAnswer: 'C',
  },
  // #24
  {
    question: "請問反霸凌專線電話是幾號？",
    selections: [
      {
        label: "0800-200-885",
        value: "A",
      },
      {
        label: "0800-885-885",
        value: "B",
      },
      {
        label: "0800-002-002",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #25
  {
    question: "新手爸爸常在孩子遇到問題可以？",
    selections: [
      {
        label: "把照顧寶寶的責任都給媽媽",
        value: "A",
      },
      {
        label: "尋求保母系統、托嬰中心等協助",
        value: "B",
      },
      {
        label: "忽略不管，久了就好",
        value: "C",
      },
    ],
    correctAnswer: 'B',
  },
  // #26
  {
    question: "下列何者為可以降低失智症的發生機率",
    selections: [
      {
        label: "正確的飲食",
        value: "A",
      },
      {
        label: "戒菸",
        value: "B",
      },
      {
        label: "維持適當體重",
        value: "C",
      },
      {
        label: "以上皆是",
        value: "D",
      },
    ],
    correctAnswer: 'A',
  },
  // #27
  {
    question: "調適升學壓力的第一步是：",
    selections: [
      {
        label: "只做自己想做的事",
        value: "A",
      },
      {
        label: "先讀書就對了",
        value: "B",
      },
      {
        label: "對目標要有動機",
        value: "C",
      },
    ],
    correctAnswer: 'C',
  },
  // #28
  {
    question: "以下何者不是網路霸凌的行為？",
    selections: [
      {
        label: "幫喜歡的文章按讚",
        value: "A",
      },
      {
        label: "暴露他人隱私",
        value: "B",
      },
      {
        label: "騷擾他人",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #29
  {
    question: "兒少遭受網路霸凌的經驗中，排名第一的是：",
    selections: [
      {
        label: "購物網站，如：蝦皮/Amazon",
        value: "A",
      },
      {
        label: "社群網站，如：FB, Twitter。",
        value: "B",
      },
      {
        label: "網路遊戲，如：魔獸爭霸戰、傳說對決。",
        value: "C",
      },
    ],
    correctAnswer: 'B',
  },
  // #30
  {
    question: "親友可以透過甚麼方式減少老年人的孤獨感？",
    selections: [
      {
        label: "透過電話或書信與家裡聯絡",
        value: "A",
      },
      {
        label: "讓他們了解自己的狀況",
        value: "B",
      },
      {
        label: "透過通訊軟體保持聯繫",
        value: "C",
      },
      {
        label: "以上皆是",
        value: "D",
      },
    ],
    correctAnswer: 'D',
  },
  // #31
  {
    question: "何者不是被性侵害的正確方法？",
    selections: [
      {
        label: "立即更衣",
        value: "A",
      },
      {
        label: "撥打110/113",
        value: "B",
      },
      {
        label: "立即到醫院接受診療、驗傷及蒐證",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #32
  {
    question: "疫情間可以怎麼做緩解心理壓力？",
    selections: [
      {
        label: "適量閱讀疫情心情，保持平靜，降低焦慮",
        value: "A",
      },
      {
        label: "利用網路、電話，支持鼓勵親友",
        value: "B",
      },
      {
        label: "保持希望，相信疫情終會過去",
        value: "C",
      },
      {
        label: "以上皆是",
        value: "D",
      },
    ],
    correctAnswer: 'D',
  },
  // #33
  {
    question: "請問男性關懷專線號碼是？",
    selections: [
      {
        label: "0800-013-999",
        value: "A",
      },
      {
        label: "0700-013-999",
        value: "B",
      },
      {
        label: "0900-013-999",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
]