<template>
    <div class="stage4">
        <StageWrapper
            ref="stage"
            :stage-config="stageConfig"
            :score="score"
            @due="$emit('end', score)"
        >
            <div class="question">{{ questionConfig.question }}</div>
            <div class="main">
                <div id="effective-zone" :key="`effective-zone${key}`">
                    <div
                        v-for="(ques, i) in questionConfig.subQuestion"
                        :key="`drop${i}`"
                        class="questino-sectin"
                    >
                        <div :ref="`drop_${i}`" class="drop">
                            <img
                                src="@/assets/game/stages/stage4/bg.png"
                                alt=""
                                width="100%"
                            />
                        </div>
                        <div class="text">{{ ques.label }}</div>
                    </div>
                </div>
                <div class="option-section" :key="`option-section${key}`">
                    <div
                        v-for="(ques, i) in questionConfig.options"
                        :key="`option${i}`"
                        class="option-cell"
                        :label="ques.value"
                    >
                        {{ ques.label }}
                    </div>
                </div>
            </div>
        </StageWrapper>
        <transition name="fade" mode="out-in">
            <Popup v-if="popupState" @ready="onReady" />
        </transition>
    </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import gsap from "gsap";
import { Draggable } from "gsap/Draggable";

gsap.registerPlugin(Draggable);

import StageWrapper from "@/components/Game/Wrappers/StageWrapper.vue";
import Popup from "@/components/Game/Popups/Stage4.vue";
import STAGE_CONFIG from "@/config/stageConfig";
import QUESTION_CONFIG from "@/config/questionConfig";

let DRAGABLE;
// TODO 時間會停掉

export default {
    components: { StageWrapper, Popup },
    data() {
        return {
            popupState: true,
            answers: {},

            questionPool: QUESTION_CONFIG.stage4,
            questionOrder: [],
            questionConfig: {},
            stageConfig: STAGE_CONFIG.stage4,
            score: 0,
            key: +new Date(),
        };
    },
    computed: {
        ...mapState({
            user: (stage) => stage.user,
        }),
    },
    created() {
        this.setQuestionOrder();
        this.questionConfig = this.questionPool[this.questionOrder.pop()];
    },
    mounted() {
        this.setDragable();
    },
    methods: {
        setQuestionOrder() {
            const ORDER = [];
            this.questionPool.forEach((item, index) => {
                ORDER.push(index);
            });
            this.questionOrder = _.shuffle(ORDER);
        },
        onReady() {
            this.popupState = false;
            this.$refs.stage.$refs.countdown.onStart();
        },
        onChangeQuestion(delay) {
            setTimeout(() => {
                if (this.questionOrder.length === 0) this.$emit("end", this.score);
                this.questionConfig =
                    this.questionPool[this.questionOrder.pop()];
                this.updateKey();
                this.setDragable();
            }, delay || 1000);
        },
        setDragable() {
            this.$nextTick(() => {
                DRAGABLE = Draggable.create(".option-cell", {
                    type: "x,y",
                    inertia: true,
                    edgeResistance: 0.65,
                    onReleaseParams: [this.checkOver, this.$refs],
                    onRelease(checkOver, refs) {
                        if (this.hitTest("#effective-zone")) {
                            const ANSWER_LABEL =
                                this.target.getAttribute("label");
                            let isCorrect;
                            let hitElement;
                            _.forEach(refs, (value, key) => {
                                // exclude Vue ifself
                                if (!value.$refs && value[0]) {
                                    if (this.hitTest(value[0])) {
                                        const HIT_LABEL = key.replace(
                                            "drop_",
                                            ""
                                        );
                                        isCorrect = ANSWER_LABEL === HIT_LABEL;
                                        hitElement = value[0];
                                    }
                                }
                            });
                            if (isCorrect) {
                                hitElement.innerText = "";
                                hitElement.append(this.target);
                                gsap.to(this.target, {
                                    duration: 0,
                                    x: 0,
                                    y: 0,
                                });
                                checkOver(refs);
                            } else {
                                gsap.to(this.target, {
                                    duration: 0.5,
                                    x: 0,
                                    y: 0,
                                });
                            }
                        } else {
                            gsap.to(this.target, {
                                duration: 0.5,
                                x: 0,
                                y: 0,
                            });
                        }
                    },
                });
            });
        },
        checkOver(refs) {
            this.score += 1;
            let TOTAL = 0;
            _.forEach(refs, (value) => {
                if (
                    !value.$refs &&
                    value[0] &&
                    value[0].children[0].getAttribute("label")
                ) {
                    TOTAL += value[0].children.length;
                }
            });
            if (TOTAL === this.questionConfig.subQuestion.length)
                this.onChangeQuestion(500);
        },
        updateKey() {
            this.key = +new Date();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/game/QuestionSelectStage.scss";
.main {
    flex: 1;
    width: 90%;
    padding-top: 10px;
    margin: auto;
    overflow: auto;
}
.question {
    width: 90%;
    background-color: #ffeabb;
    padding: 5px 10px;
}
.questino-sectin {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .text {
        flex: 1;
        margin-left: 10px;
        word-break: break-all;
        margin-top: 0.2em;
    }
}
.drop {
    // border: 1px dotted;
    box-sizing: border-box;
    height: 35px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .option-cell {
        margin: 0;
        width: 100%;
    }
}
#effective-zone {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.option-section {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.option-cell {
    cursor: unset;
    text-align: center;
    padding: 5px 0;
    width: 45%;
    background-color: #eb7272;
    color: #fff;
    border-radius: 12px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
