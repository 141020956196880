<template>
    <div class="stage0">
        <StageWrapper
            :stage-config="stageConfig"
            :score="score"
            @due="$emit('end', score)"
        >
            <div class="question" v-html="questionConfig.question"></div>
            <div class="selections">
                <div
                    v-for="(selection, index) in questionConfig.selections"
                    :key="index"
                    :class="{
                        correct: judgeCorrect(
                            selected,
                            selection,
                            questionConfig.correctAnswer
                        ),
                        incorrect: judgeIncorrect(
                            selected,
                            selection,
                            questionConfig.correctAnswer
                        ),
                    }"
                    @click="onClick(selection)"
                >
                    {{ selection.label }}
                </div>
            </div>
        </StageWrapper>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { updateStage0Form } from "@/db";
import StageWrapper from "@/components/Game/Wrappers/StageWrapper.vue";
import STAGE_CONFIG from "@/config/stageConfig";
import QUESTION_CONFIG from "@/config/questionConfig";
import questionSelectStage from "@/mixins/questionSelectStage";

export default {
    mixins: [questionSelectStage],
    components: { StageWrapper },
    data() {
        return {
            answers: {},

            cerrentQuestion: 1,
            selected: undefined,
            questionPool: QUESTION_CONFIG.stage0,
            questionConfig: {},
            stageConfig: STAGE_CONFIG.stage0,
            score: 0,
        };
    },
    computed: {
        ...mapState({
            user: (stage) => stage.user,
        }),
    },
    created() {
        this.questionConfig = this.questionPool[this.cerrentQuestion - 1];
    },
    methods: {
        onClick(selection) {
            if (this.selected) return;
            this.selected = selection.value;
            this.score += selection.score;
            this.answers[this.questionConfig.question] = selection.value;
            this.onChangeQuestion(500);
        },
        async onChangeQuestion(delay) {
            if (this.cerrentQuestion === this.questionPool.length) {
                await updateStage0Form(this.user.userId, this.answers);
                this.$emit("end", this.score);
            }
            setTimeout(() => {
                this.selected = undefined;
                this.cerrentQuestion += 1;
                this.questionConfig =
                    this.questionPool[this.cerrentQuestion - 1];
            }, delay || 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/game/QuestionSelectStage.scss";
</style>
