<template>
  <div class="stage3">
    <StageWrapper
      ref="stage"
      :stage-config="stageConfig"
      :score="score"
      @due="$emit('end', score)"
      class="stage-wrapper"
    >
      <div class="stage-3-content" :style="{ height: `${innerHeight}px` }">
        <div class="block" :class="{ shake: animated }">
          <div
            class="road"
            v-for="roadIndex in roadNum"
            :key="`road-${roadIndex}`"
          >
            <div
              class="position box"
              v-for="posIndex in positionNum"
              :key="`position-${roadIndex}-${posIndex}`"
              :ref="`position-${roadIndex}-${posIndex}`"
            >
              <img
                class="position-image"
                :src="gameGrid[roadIndex - 1][posIndex - 1]"
                alt=""
                v-if="gameGrid[roadIndex - 1][posIndex - 1]"
              />
              <div class="position-image" v-else></div>
            </div>
            <div class="button box">
              <img
                class="btn-image"
                :src="
                  require(`@/assets/game/stages/stage3/btn${roadIndex}.png`)
                "
                alt=""
                @click="hit(roadIndex - 1)"
              />
            </div>
          </div>
        </div>
      </div>
    </StageWrapper>
    <transition name="fade" mode="out-in">
      <Popup v-if="popupState" @ready="onReady" />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import StageWrapper from '@/components/Game/Wrappers/StageWrapper.vue';
import Popup from '@/components/Game/Popups/Stage3.vue';
import STAGE_CONFIG from '@/config/stageConfig';

export default {
  components: { StageWrapper, Popup },
  data() {
    return {
      innerWidth: undefined,
      innerHeight: undefined,
      popupState: true,
      animated: false,
      stageConfig: STAGE_CONFIG.stage3,
      score: 0,
      roadNum: 4,
      positionNum: 5,
      monsterImage: [
        require(`@/assets/game/stages/stage3/ip1.png`),
        require(`@/assets/game/stages/stage3/ip2.png`),
      ],
      gameGrid: [],
    };
  },
  computed: {
    ...mapState({
      user: (stage) => stage.user,
    }),
  },
  created() {
    this.initGame();
  },
  methods: {
    onReady() {
      this.popupState = false;
      this.$refs.stage.$refs.countdown.onStart();
    },
    initGame() {
      this.innerHeight = window.innerHeight - 200;
      // init
      for (let i = 0; i < this.roadNum; i++) {
        this.gameGrid[i] = [];
        for (let j = 0; j < this.positionNum; j++) {
          this.gameGrid[i][j] = '';
        }
      }
      // set monster
      for (let i = 0; i < this.positionNum; i++) {
        let randomRoad = this.getRandom(0, this.roadNum - 1);
        this.setMonster(randomRoad, i);
      }
    },
    setMonster(row, pos) {
      let randomMonster = this.getRandom(0, this.monsterImage.length - 1);
      let monsterImage = this.getMonsterImage(randomMonster);
      this.gameGrid[row][pos] = monsterImage;
    },
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getMonsterImage(num) {
      return this.monsterImage[num];
    },
    hit(row) {
      let item = this.gameGrid[row][this.positionNum - 1];
      if (item) {
        this.hitSuccess();
      } else {
        this.hitFail();
      }
    },
    hitSuccess() {
      this.score += 2;
      this.refreshGame();
    },
    hitFail() {
      this.score -= 1;
      const self = this;
      self.animated = true;
      setTimeout(() => {
        self.animated = false;
      }, 1000);
    },
    refreshGame() {
      for (let j = this.positionNum - 1; j > 0; j--) {
        for (let i = this.roadNum - 1; i >= 0; i--) {
          this.gameGrid[i][j] = this.gameGrid[i][j - 1];
        }
      }
      for (let x = this.roadNum - 1; x >= 0; x--) {
        this.gameGrid[x][0] = '';
      }

      let randomRoad = this.getRandom(0, this.roadNum - 1);
      this.setMonster(randomRoad, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.stage-3-content {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.block {
  border: solid 2px #ffa158;
  border-radius: 7px;
  margin-top: 10px;
  width: 95%;
  background-color: #ffe8ae;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../../assets/game/stages/stage3/bg.jpg');
  height: 100%;
}

.road {
  flex: 1;
  height: 90%;
  border-right: 3px dashed #ffa158;

  .box {
    width: 100%;
    height: 16.6%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .position {
    position: relative;

    .position-image {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 90%;
      transform: translate(-50%, -50%);
    }
  }

  .button {
    .btn-image {
      height: 90%;
    }
  }
}
.road:last-child {
  border-right: none;
}

.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
