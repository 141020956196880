<template>
    <PageWrapper
        class="stage-wrapper"
        :style="{
            'background-image': `url(${require(`@/assets/game/stages/game-bg/bg.jpg`)})`,
        }"
    >
        <div class="header">
            <div class="countdown">
                <Countdown
                    ref="countdown"
                    v-if="stageConfig.duration"
                    :duration="stageConfig.duration"
                    @due="$emit('due')"
                    @tick="$emit('tick', $event)"
                />
            </div>
            <div class="character">
                <img
                    :src="
                        require(`@/assets/game/stages/stage${stageConfig.stage}/icon.png`)
                    "
                    width="100%"
                />
            </div>
            <div class="score">
                <Score
                    v-if="stageConfig.plugins && stageConfig.plugins.score"
                    :score="score"
                />
            </div>
        </div>
        <slot />
    </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/Game/Wrappers/PageWrapper.vue";
import Countdown from "@/components/Game/Plugins/Countdown.vue";
import Score from "@/components/Game/Plugins/Score.vue";

export default {
    components: { PageWrapper, Countdown, Score },
    props: {
        stageConfig: Object,
        score: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style lang="scss" scoped>
.stage-wrapper {
    display: flex;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > * {
            width: 30%;
            height: 100%;
            display: flex;
            align-items: center;
        }
        .character img {
            margin-bottom: 10px;
        }
    }
}
</style>
