<template>
    <div class="countdown">
        <div class="inner">
            <div class="icon">
                <img
                    src="@/assets/game/stages/components/time.png"
                    alt=""
                    width="100%"
                />
            </div>
            <div class="text">
                <div class="label">Time</div>
                <div class="time-last">{{ timeLast }}</div>
            </div>
        </div>
    </div>
</template>

<script>
let INTERVAL;
export default {
    props: {
        duration: Number,
    },
    data() {
        return {
            timeLast: this.duration,
        };
    },
    destroyed() {
        clearInterval(INTERVAL);
    },
    methods: {
        onStart() {
            INTERVAL = setInterval(() => {
                if (this.timeLast > 0) {
                    this.timeLast -= 1;
                    this.$emit("tick", this.timeLast);
                } else {
                    clearInterval(INTERVAL);
                    this.$emit("due");
                }
            }, 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
.countdown {
    width: 100%;
    margin: auto;
    padding: 2px 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: solid 2px #ffcfcf;
    border-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    .inner {
        width: 80%;
        margin: auto;
        display: flex;
        align-items: center;
    }
    .icon {
        margin-right: 10px;
        width: 30px;
    }
    .text {
        color: #ce4a4a;
        font-weight: bold;
        .label {
            font-size: 12px;
        }
        .time-last {
            font-size: 20px;
        }
    }
}
</style>
