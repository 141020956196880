export default [
  {
    question: "性別",
    selections: [
      {
        label: "男生",
        value: "男生",
        score: 0,
      },
      {
        label: "女生",
        value: "女生",
        score: 0,
      },
      {
        label: "其他",
        value: "其他",
        score: 0,
      },
    ],
  },
  {
    question: "年齡",
    selections: [
      {
        label: "0 - 14歲",
        value: "0 - 14歲",
        score: 0,
      },
      {
        label: "15 - 24歲",
        value: "15 - 24歲",
        score: 0,
      },
      {
        label: "25 - 34歲",
        value: "25 - 34歲",
        score: 0,
      },
      {
        label: "35 - 44 歲",
        value: "35 - 44 歲",
        score: 0,
      },
      {
        label: "45 - 54 歲",
        value: "45 - 54 歲",
        score: 0,
      },
      {
        label: "55 - 64歲",
        value: "55 - 64歲",
        score: 0,
      },
      {
        label: "65 - 74歲",
        value: "65 - 74歲",
        score: 0,
      },
      {
        label: "75歲以上",
        value: "75歲以上",
        score: 0,
      },
    ],
  },
  {
    question: "請回想最近一星期（包含現在），你在下列問題感到困擾或苦惱的程度\n<strong style='color: #ed6f6f;'>有睡眠困難,譬如難以入睡、易醒或早醒</strong>",
    selections: [
      {
        label: "完全沒有",
        value: "完全沒有",
        score: 0,
      },
      {
        label: "輕微",
        value: "輕微",
        score: 1,
      },
      {
        label: "中等程度",
        value: "中等程度",
        score: 2,
      },
      {
        label: "厲害",
        value: "厲害",
        score: 3,
      },
      {
        label: "非常厲害",
        value: "非常厲害",
        score: 4,
      },
    ],
  },
  {
    question: "請回想最近一星期（包含現在），你在下列問題感到困擾或苦惱的程度\n<strong style='color: #ed6f6f;'>感覺緊張不安</strong>",
    selections: [
      {
        label: "完全沒有",
        value: "完全沒有",
        score: 0,
      },
      {
        label: "輕微",
        value: "輕微",
        score: 1,
      },
      {
        label: "中等程度",
        value: "中等程度",
        score: 2,
      },
      {
        label: "厲害",
        value: "厲害",
        score: 3,
      },
      {
        label: "非常厲害",
        value: "非常厲害",
        score: 4,
      },
    ],
  },
  {
    question: "請回想最近一星期（包含現在），你在下列問題感到困擾或苦惱的程度\n<strong style='color: #ed6f6f;'>覺得容易苦惱或動怒</strong>",
    selections: [
      {
        label: "完全沒有",
        value: "完全沒有",
        score: 0,
      },
      {
        label: "輕微",
        value: "輕微",
        score: 1,
      },
      {
        label: "中等程度",
        value: "中等程度",
        score: 2,
      },
      {
        label: "厲害",
        value: "厲害",
        score: 3,
      },
      {
        label: "非常厲害",
        value: "非常厲害",
        score: 4,
      },
    ],
  },
  {
    question: "請回想最近一星期（包含現在），你在下列問題感到困擾或苦惱的程度\n<strong style='color: #ed6f6f;'>感覺憂鬱、心情低落</strong>",
    selections: [
      {
        label: "完全沒有",
        value: "完全沒有",
        score: 0,
      },
      {
        label: "輕微",
        value: "輕微",
        score: 1,
      },
      {
        label: "中等程度",
        value: "中等程度",
        score: 2,
      },
      {
        label: "厲害",
        value: "厲害",
        score: 3,
      },
      {
        label: "非常厲害",
        value: "非常厲害",
        score: 4,
      },
    ],
  },
  {
    question: "請回想最近一星期（包含現在），你在下列問題感到困擾或苦惱的程度\n<strong style='color: #ed6f6f;'>覺得比不上別人</strong>",
    selections: [
      {
        label: "完全沒有",
        value: "完全沒有",
        score: 0,
      },
      {
        label: "輕微",
        value: "輕微",
        score: 1,
      },
      {
        label: "中等程度",
        value: "中等程度",
        score: 2,
      },
      {
        label: "厲害",
        value: "厲害",
        score: 3,
      },
      {
        label: "非常厲害",
        value: "非常厲害",
        score: 4,
      },
    ],
  },
  {
    question: "請回想最近一星期（包含現在），你在下列問題感到困擾或苦惱的程度\n<strong style='color: #ed6f6f;'>有自殺的想法</strong>",
    selections: [
      {
        label: "完全沒有",
        value: "完全沒有",
        score: 0,
      },
      {
        label: "輕微",
        value: "輕微",
        score: 1,
      },
      {
        label: "中等程度",
        value: "中等程度",
        score: 2,
      },
      {
        label: "厲害",
        value: "厲害",
        score: 3,
      },
      {
        label: "非常厲害",
        value: "非常厲害",
        score: 4,
      },
    ],
  },
]