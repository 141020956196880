export default [
  // #1
  {
    question: "請問世界心理健康日是哪一天？",
    selections: [
      {
        label: "9.9",
        value: "A",
      },
      {
        label: "10.10",
        value: "B",
      },
      {
        label: "11.11",
        value: "C",
      },
    ],
    correctAnswer: 'B',
  },
  // #2
  {
    question: "請問衛福部心理健康五步驟則縮寫為？",
    selections: [
      {
        label: "SMILE",
        value: "A",
      },
      {
        label: "HAPPY",
        value: "B",
      },
      {
        label: "LAUGH",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #3
  {
    question: "請問衛福部24小時免付費安心專線電話是幾號?",
    selections: [
      {
        label: "1922",
        value: "A",
      },
      {
        label: "1925(依舊愛我)",
        value: "B",
      },
      {
        label: "1995",
        value: "C",
      },
    ],
    correctAnswer: 'B',
  },
  // #4
  {
    question: "下列何者不是青春期少女會有的生理變化",
    selections: [
      {
        label: "身體易疲倦",
        value: "A",
      },
      {
        label: "皮膚出油",
        value: "B",
      },
      {
        label: "體重減輕",
        value: "C",
      },
    ],
    correctAnswer: 'C',
  },
  // #5
  {
    question: "更年期多發生於幾歲?",
    selections: [
      {
        label: "45~55歲",
        value: "A",
      },
      {
        label: "50~60歲",
        value: "B",
      },
      {
        label: "40~50歲",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #6
  {
    question: "請問教育部反霸凌專線電話是幾號？",
    selections: [
      {
        label: "0800-200-885",
        value: "A",
      },
      {
        label: "0800-123-885",
        value: "B",
      },
      {
        label: "0800-321-885",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #7
  {
    question: "哪個季節容易誘發憂鬱症？",
    selections: [
      {
        label: "春季",
        value: "A",
      },
      {
        label: "夏季",
        value: "B",
      },
      {
        label: "冬季",
        value: "C",
      },
    ],
    correctAnswer: 'C',
  },
  // #8
  {
    question: "幾％的重度憂鬱症患者有酗酒與藥物濫用情形？",
    selections: [
      {
        label: "20%",
        value: "A",
      },
      {
        label: "30%",
        value: "B",
      },
      {
        label: "40%",
        value: "C",
      },
    ],
    correctAnswer: 'B',
  },
  // #9
  {
    question: "有憂鬱症狀者的手機使用頻率，是沒有憂鬱症狀者的幾倍？",
    selections: [
      {
        label: "2倍",
        value: "A",
      },
      {
        label: "3倍",
        value: "B",
      },
      {
        label: "5倍",
        value: "C",
      },
    ],
    correctAnswer: 'B',
  },
  // #10
  {
    question: "在憂鬱症服藥期間，不適合吃哪種水果",
    selections: [
      {
        label: "香蕉",
        value: "A",
      },
      {
        label: "西瓜",
        value: "B",
      },
      {
        label: "葡萄柚",
        value: "C",
      },
    ],
    correctAnswer: 'C',
  },
  // #11
  {
    question: "憂鬱症最早的紀錄出現於",
    selections: [
      {
        label: "美索不達米亞文明",
        value: "A",
      },
      {
        label: "文藝復興時期",
        value: "B",
      },
      {
        label: "工業革命期間",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #12
  {
    question: "全國張老師專線號碼是幾號？",
    selections: [
      {
        label: "1980",
        value: "A",
      },
      {
        label: "1990",
        value: "B",
      },
      {
        label: "1970",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #13
  {
    question: "全國生命線號碼是幾號？",
    selections: [
      {
        label: "1995",
        value: "A",
      },
      {
        label: "1990",
        value: "B",
      },
      {
        label: "1992",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #14
  {
    question: "何者不是抗憂鬱食物",
    selections: [
      {
        label: "炸雞",
        value: "A",
      },
      {
        label: "香蕉",
        value: "B",
      },
      {
        label: "菠菜",
        value: "C",
      },
    ],
    correctAnswer: 'C',
  },
  // #15
  {
    question: "何者不是治療憂鬱症的正確方式",
    selections: [
      {
        label: "藥物治療",
        value: "A",
      },
      {
        label: "心理治療",
        value: "B",
      },
      {
        label: "不用理他，久了就好了",
        value: "C",
      },
    ],
    correctAnswer: 'C',
  },
  // #16
  {
    question: "憂鬱症對思考/行動會有什麼影響",
    selections: [
      {
        label: "變慢",
        value: "A",
      },
      {
        label: "加快",
        value: "B",
      },
      {
        label: "不會有影響",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #17
  {
    question: "我們可以用心情溫度計來檢測自己的情緒狀態",
    selections: [
      {
        label: "可以",
        value: "A",
      },
      {
        label: "不行",
        value: "B",
      },
      {
        label: "只能測體溫",
        value: "C",
      },
    ],
    correctAnswer: 'A',
  },
  // #18
  {
    question: "人的健康包含",
    selections: [
      {
        label: "心理健康",
        value: "A",
      },
      {
        label: "生理健康",
        value: "B",
      },
      {
        label: "以上皆是",
        value: "C",
      },
    ],
    correctAnswer: 'C',
  },
  // #19
  {
    question: "我會用一問(關懷)二應(回應)三轉介(提供資源)，主動關懷身旁的人",
    selections: [
      {
        label: "Yes",
        value: "A",
      },
      {
        label: "No",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #20
  {
    question: "每個人都可以成為自殺守門人",
    selections: [
      {
        label: "Yes",
        value: "A",
      },
      {
        label: "No",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #21
  {
    question: "不管多忙我們都要給自己喘息的空間，注意自我壓力的警訊",
    selections: [
      {
        label: "Yes",
        value: "A",
      },
      {
        label: "No",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #22
  {
    question: "如果有朋友需要協助，我願意提供(免費)安心專線給他",
    selections: [
      {
        label: "Yes",
        value: "A",
      },
      {
        label: "No",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #23
  {
    question: "臺中市各區衛生所都有提供心理諮詢服務",
    selections: [
      {
        label: "Yes",
        value: "A",
      },
      {
        label: "No",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #24 
  {
    question: "如果是65歲以上長輩，衛生局有提供心理師到宅關懷服務",
    selections: [
      {
        label: "Yes",
        value: "A",
      },
      {
        label: "No",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #25
  {
    question: "台中市心理健康月是9-10月",
    selections: [
      {
        label: "Yes",
        value: "A",
      },
      {
        label: "No",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #26
  {
    question: "如果是65歲以上長輩，衛生所可以提供長者憂鬱篩檢服務",
    selections: [
      {
        label: "Yes",
        value: "A",
      },
      {
        label: "No",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #27
  {
    question: "心情不好，想要找人談談，可以撥打下列哪支電話?",
    selections: [
      {
        label: "安心專線1925",
        value: "A",
      },
      {
        label: "張老師中心1980",
        value: "B",
      },
      {
        label: "生命線1995",
        value: "C",
      },
      {
        label: "以上皆是",
        value: "D",
      },
    ],
    correctAnswer: 'D',
  },
  // #28
  {
    question: "如果發現親朋好友心情不好，可以怎麼幫他？",
    selections: [
      {
        label: "1問",
        value: "A",
      },
      {
        label: "2應",
        value: "B",
      },
      {
        label: "3轉介",
        value: "C",
      },
      {
        label: "1、2、3皆是",
        value: "D",
      },
      {
        label: "不用幫忙，他會自己調適好",
        value: "E",
      },
    ],
    correctAnswer: 'D',
  },
  // #29
  {
    question: "下列何者是正確的精神疾病用語",
    selections: [
      {
        label: "瘋子",
        value: "A",
      },
      {
        label: "神經病",
        value: "B",
      },
      {
        label: "精神分裂症",
        value: "C",
      },
      {
        label: "思覺失調症",
        value: "D",
      },
    ],
    correctAnswer: 'D',
  },
  // #30
  {
    question: "台中市有提供哪心理健康服務",
    selections: [
      {
        label: "免費定點諮詢服務",
        value: "A",
      },
      {
        label: "長者憂鬱篩檢",
        value: "B",
      },
      {
        label: "長者到宅心理諮詢服務 ",
        value: "C",
      },
      {
        label: "以上皆是",
        value: "D",
      },
    ],
    correctAnswer: 'D',
  },
  // #31
  {
    question: "1問2應3轉介的1問指的是?",
    selections: [
      {
        label: "隨便問問，他有說出來就好",
        value: "A",
      },
      {
        label: "主動詢問、積極傾聽",
        value: "B",
      },
    ],
    correctAnswer: 'B',
  },
  // #32
  {
    question: "1問2應3轉介的2應指的是?",
    selections: [
      {
        label: "表達同理心、不責備",
        value: "A",
      },
      {
        label: "一直提供建議、經驗分享，我說的最對",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #33
  {
    question: "1問2應3轉介的3轉介指的是?",
    selections: [
      {
        label: "趕快叫其他朋友來一起聽",
        value: "A",
      },
      {
        label: "引導他去專業的醫療員或服務",
        value: "B",
      },
    ],
    correctAnswer: 'B',
  },
  // #34
  {
    question: "SMILE中的S，代表",
    selections: [
      {
        label: "SLEEP(睡得好，沒煩惱)",
        value: "A",
      },
      {
        label: "Stop(停下來，沒煩惱)",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #35
  {
    question: "SMILE中的M，代表",
    selections: [
      {
        label: "MOBILE(常動動，好放鬆)",
        value: "A",
      },
      {
        label: "MILK(喝牛奶，好放鬆)",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #36
  {
    question: "SMILE中的I，代表",
    selections: [
      {
        label: "INTERACT(愛開講，好舒壓)",
        value: "A",
      },
      {
        label: "I LOVE U(挖愛哩捏)",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #37
  {
    question: "SMILE中的L，代表",
    selections: [
      {
        label: "LOVE(愛滿滿)",
        value: "A",
      },
      {
        label: "LAUGH(開心笑)",
        value: "B",
      },
      {
        label: "以上皆是",
        value: "C",
      },
    ],
    correctAnswer: 'C',
  },
  // #38
  {
    question: "SMILE中的E，代表",
    selections: [
      {
        label: "EAT(吃得健康，身心upup)",
        value: "A",
      },
      {
        label: "EASY(卡簡單，卡好生活)",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
  // #39
  {
    question: "哪個性別的人，較容易得到憂鬱症",
    selections: [
      {
        label: "女性",
        value: "A",
      },
      {
        label: "男性",
        value: "B",
      },
    ],
    correctAnswer: 'A',
  },
]