<template>
    <div class="stage" :style="cssStyle">
        <component :is="currentStageComponent" @end="onGoResult" />
    </div>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";

import { updateDbProgress } from "@/db";
import Stage0 from "@/components/Game/Stages/Stage0.vue";
import Stage1 from "@/components/Game/Stages/Stage1.vue";
import Stage2 from "@/components/Game/Stages/Stage2.vue";
import Stage3 from "@/components/Game/Stages/Stage3.vue";
import Stage4 from "@/components/Game/Stages/Stage4.vue";
import Stage5 from "@/components/Game/Stages/Stage5.vue";

import StageConfig from "@/config/stageMap";

export default {
    components: { Stage0, Stage1, Stage2, Stage3, Stage4, Stage5 },
    computed: {
        ...mapState({
            progress: (stage) => stage.progress,
            user: (stage) => stage.user,
        }),
        currentStageComponent() {
            return `Stage${this.$route.params.stage}`;
        },
        cssStyle() {
            return `--navbar-height: ${this.$root.navbarHeight}px`;
        },
    },
    created() {
        const STAGE = this.$route.params.stage;
        if (!this.isAllowToPlay(StageConfig[STAGE].openDate))
            this.$router.replace({ name: "Game_Map" });
    },
    methods: {
        ...mapMutations({
            setScore: "setScore",
            updateProgress: "updateProgress",
        }),
        onGoResult(score) {
            this.setScore(score);
            this.$router.push({
                name: "Game_Result",
                params: { stage: this.$route.params.stage },
            });
            this.sendData(score);
        },
        sendData(score) {
            const PAYLOAD = {
                stage: this.$route.params.stage,
                score,
            };
            this.updateProgress(PAYLOAD);
            updateDbProgress(this.user.userId, this.progress);
        },
        isAllowToPlay(date) {
            const CURRENT_TIME = process.env.VUE_APP_CURRENT_TIME;
            const IS_DATE_PASSED = !moment(date).isAfter(moment(CURRENT_TIME));
            return IS_DATE_PASSED;
        },
    },
};
</script>

<style lang="scss" scoped>
.stage {
    background-color: #fefce9;
    height: calc(100vh - var(--navbar-height));
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
